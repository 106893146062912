:root {
	--primary-color: #8B131D;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
	margin: 0;
	padding: 0;
	height: 100%;
	width : 100%;
}

#root {
	display: grid;
	align-items: flex-start;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	max-width : 100%;
}

.header-and-nav {
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 100;
}

.search-container {
	width: 100%;
	display: flex;
	top: 6%;
	height: 19%;
	justify-content: space-between;
	background  : #696969;
	z-index: 1;
}

.search {
	display: grid;
	grid-template-columns: 80% auto;
	width: 85%;
	margin: auto;
	height: fit-content;
	padding-top: 20px;
	z-index: 1;
}

.alfabeto {
	display: flex;
	margin: auto;
	padding: 0;
	grid-template-rows: auto auto;
}

.alfabeto li {
	margin: 0 1.25rem .5rem 0;
	display: flex;
	padding: .5rem;
	width: 2rem;
	justify-content: center;
	list-style: none;
	background: white;
	border-radius: .25rem;
}

.alfabeto li a {
	text-decoration: none;
	color: #14141f;
}

.alfabeto li a:hover {
	color: gray;
}

.wapi {
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	height: 100%;
	width: 85%;
}

.wapi h1 {
	width: 100%;
	margin-bottom: 35px;
	font-size: 40px;
}

.wapi h2 {
	font-size: 2rem;
	margin-bottom: .125rem;
}

.wapi ul {
	list-style: none;
	width: 100%;
	padding: 0;
}

.wapi li {
	margin: .5rem 0;
	width: 100%;
}

.wapi a {
	font-size: 1.25rem;
	font-weight: bold;
	color: var(--primary-color);
	text-decoration: none;
}

.wapi-header {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

footer {
	width: auto;
	height: 200px;
	margin-top: 100px;
	background: var(--primary-color);
}

.word-card {
	border       : solid thin lightgray;
	padding      : .5rem;
	border-radius: .3rem;
	box-shadow   : 1px 1px 3px #DDD;
}

.authenticator-button {
	align-self: flex-end;
	display: flex;
	justify-content: flex-end;
	padding: 2rem 0;
	align-items: center;
}

.loading {
	position       : fixed;
	top            : 0;
	left           : 0;
	background     : #a9a9a970;
	height         : 100%;
	width          : 100%;
	display        : flex;
	justify-content: center;
	align-items    : center;
	z-index: 0;
}

@media (max-width: 700px) {
	#root {
		max-width: 100vw;
		display: block;
	}
	.logo figure {
		width: 100px;
		height: 40px;
	}
	.logo h1 {
		font-size: 1rem !important;
	}
	.alfabeto li {
		margin: 0 .5rem .5rem 0;
		padding: .5rem;
		width: 2rem;
		border-radius: .25rem;
	}
	.authenticator-button {
		padding: 0;
		position: fixed;
		border-radius: .5rem;
		top: .25rem;
		right: .5rem;
		color: white !important;
	}	

	.authenticator-button > * {
		color: white !important;
	}

	.authenticator-button b {
		display: none;
	}

	.search {
		display: grid;
		align-items: center;
		grid-template-columns: 85% auto;
		width: 95%;
		margin: auto;
		height: fit-content;
		padding-top: 20px;
		z-index: 1;
	}
}